import React from "react";
import FooterLogo from "../assets/AG-logo.png";
import { MdOutlineArrowUpward } from "react-icons/md";

const Footer = () => {
  return (
    <div className="footer__wrapper">
      <a href="/" className="footer__logo">
        <img src={FooterLogo} alt="Logo" />
        <span className="footer__scroll-top">
          Top <MdOutlineArrowUpward />
        </span>
      </a>
      <div className="footer__link--wrapper">
        <div>
          <a href="https://github.com/AdrianeQQ">Github</a>
        </div>
        <div>
          <a href="https://www.linkedin.com/in/adrian-garbowski/">LinkedIn</a>
        </div>
        <div>
          <a href="mailto:me@adriangarbowski.com">Email</a>
        </div>
        <div>
          <a href="/resume-eng.pdf">Resume</a>
        </div>
      </div>
      <div>Copyright &copy; 2023 Adrian Garbowski</div>
    </div>
  );
};

export default Footer;
