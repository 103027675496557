export const transferrableSkillsConfig = [
  "Confident leadership skills",
  "Effective teaching skills",
  "Quick learning ability",
  "Extraordinary time management skills",
  "Collaborative teamwork",
  "Ability to work under pressure",
  "Attention to quality and detail",
  "Multitasking capabilities",
  "Exceptional communication skills",
  "Creative problem-solving",
];
