import ecinemaCoverImage from "../assets/project-images/ecinema/ecinema-cover.jpg";
import ecinemaFrontImage from "../assets/project-images/ecinema/ecinema-front.jpg";
import ecinemaFirstImage from "../assets/project-images/ecinema/ecinema-desktop.jpg";
import ecinemaSecondImage from "../assets/project-images/ecinema/ecinema-phones-1.jpg";
import ecinemaThirdImage from "../assets/project-images/ecinema/ecinema-phones-2.jpg";

import natoursCoverImage from "../assets/project-images/natours/natours-cover.jpg";
import natoursFrontImage from "../assets/project-images/natours/natours-front.jpg";
import natoursFirstImage from "../assets/project-images/natours/natours-desktop.jpg";
import natoursSecondImage from "../assets/project-images/natours/natours-phones-1.jpg";
import natoursThirdImage from "../assets/project-images/natours/natours-phones-2.jpg";

import nexterCoverImage from "../assets/project-images/nexter/nexter-cover.jpg";
import nexterFrontImage from "../assets/project-images/nexter/nexter-front.jpg";
import nexterFirstImage from "../assets/project-images/nexter/nexter-desktop.jpg";
import nexterSecondImage from "../assets/project-images/nexter/nexter-phones-1.jpg";
import nexterThirdImage from "../assets/project-images/nexter/nexter-phones-2.jpg";

import omnifoodCoverImage from "../assets/project-images/omnifood/omnifood-cover.jpg";
import omnifoodFrontImage from "../assets/project-images/omnifood/omnifood-front.jpg";
import omnifoodFirstImage from "../assets/project-images/omnifood/omnifood-desktop.jpg";
import omnifoodSecondImage from "../assets/project-images/omnifood/omnifood-phones-1.jpg";
import omnifoodThirdImage from "../assets/project-images/omnifood/omnifood-phones-2.jpg";

import summaristCoverImage from "../assets/project-images/summarist/summarist-cover.jpg";
import summaristFrontImage from "../assets/project-images/summarist/summarist-front.jpg";
import summaristFirstImage from "../assets/project-images/summarist/summarist-desktop.jpg";
import summaristSecondImage from "../assets/project-images/summarist/summarist-phones-1.jpg";
import summaristThirdImage from "../assets/project-images/summarist/summarist-phones-2.jpg";

import trainsCoverImage from "../assets/project-images/trains/trains-cover.jpg";
import trainsFrontImage from "../assets/project-images/trains/trains-front.jpg";
import trainsFirstImage from "../assets/project-images/trains/trains-desktop.jpg";
import trainsSecondImage from "../assets/project-images/trains/trains-phones-1.jpg";
import trainsThirdImage from "../assets/project-images/trains/trains-phones-2.jpg";

const projectsConfig = [
  {
    title: "Summarist Website",
    type: "Web App",
    description:
      "During my virtual internship at Frontend Simplified, I successfully recreated a website using Next.js, implemented a robust authentication system using Google Firebase and managed global state with Redux, and seamlessly integrated subscription plans and payment processing using Stripe.",
    liveLink: "https://adrian-internship-summarist.vercel.app/",
    githubLink: "https://github.com/AdrianeQQ/adrian-internship-v2.git",
    coverImage: summaristCoverImage,
    topImage: summaristFrontImage,
    firstImage: summaristFirstImage,
    secondImage: summaristSecondImage,
    thirdImage: summaristThirdImage,
  },
  {
    title: "Polish Train System Analyser",
    type: "Web App",
    description:
      "In this project, I developed and implemented a highly efficient JavaScript algorithm to calculate the longest route for PKP Intercity trains, surpassing the unofficial Polish record and gaining national television coverage as a result of the accomplishment.",
    liveLink: "https://intercity-demo-git-master-adrianeqq.vercel.app/",
    youtubeLink: "https://www.youtube.com/watch?v=g0VPwa73vqM",
    coverImage: trainsCoverImage,
    topImage: trainsFrontImage,
    firstImage: trainsFirstImage,
    secondImage: trainsSecondImage,
    thirdImage: trainsThirdImage,
  },
  {
    title: "Natours Website",
    type: "Web App",
    description:
      "This project involved building a website designed by Jonas Schmedtmann, utilizing CSS and Sass to construct advanced components and layouts for visually captivating designs.",
    liveLink: "https://natours-udemy.vercel.app/",
    githubLink: "https://github.com/AdrianeQQ/Natours-Udemy.git",
    coverImage: natoursCoverImage,
    topImage: natoursFrontImage,
    firstImage: natoursFirstImage,
    secondImage: natoursSecondImage,
    thirdImage: natoursThirdImage,
  },
  {
    title: "Omnifood Website",
    type: "Web App",
    description:
      "This project involved building engaging website designed by Academind, I leverage HTML and CSS to build sophisticated components and layouts, resulting in visually stunning designs.",
    liveLink: "https://omnifood-udemy.vercel.app/",
    githubLink: "https://github.com/AdrianeQQ/Omnifood-Udemy.git",
    coverImage: omnifoodCoverImage,
    topImage: omnifoodFrontImage,
    firstImage: omnifoodFirstImage,
    secondImage: omnifoodSecondImage,
    thirdImage: omnifoodThirdImage,
  },
  {
    title: "ECinema Website",
    type: "Web App",
    description:
      "This project was developed using React to create a user-friendly film exploration experience, incorporating pagination functionality. By efficiently fetching data from the Films API, users can effortlessly navigate through a vast collection of films, accessing detailed information and enjoying a seamless browsing experience.",
    liveLink: "https://ecinema-week6-fes.vercel.app/",
    githubLink: "https://github.com/AdrianeQQ/Ecinema-week6-fes.git",
    coverImage: ecinemaCoverImage,
    topImage: ecinemaFrontImage,
    firstImage: ecinemaFirstImage,
    secondImage: ecinemaSecondImage,
    thirdImage: ecinemaThirdImage,
  },
  {
    title: "Nexter Website",
    type: "Web App",
    description:
      "This project involved building engaging website designed by Jonas Schmedtmann, I use CSS and Sass to create visually captivating designs by building advanced components and layouts.",
    liveLink: "https://nexter-udemy.vercel.app/",
    githubLink: "https://github.com/AdrianeQQ/Nexter-Udemy.git",
    coverImage: nexterCoverImage,
    topImage: nexterFrontImage,
    firstImage: nexterFirstImage,
    secondImage: nexterSecondImage,
    thirdImage: nexterThirdImage,
  },
];

export default projectsConfig;
