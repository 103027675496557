import { FaReact } from "react-icons/fa";
import {
  SiCss3,
  SiFirebase,
  SiHtml5,
  SiJavascript,
  SiSass,
  SiTypescript,
  SiRedux,
  SiNextdotjs,
  SiNodedotjs,
  SiMongodb,
  SiExpress,
  SiPython,
  SiCplusplus,
  SiC,
  SiCsharp,
} from "react-icons/si";

export const skillsConfig = [
  {
    icon: <SiHtml5 />,
    text: "HTML",
    color: "rgb(240, 101, 41)",
  },
  {
    icon: <SiCss3 />,
    text: "CSS",
    color: "rgb(41, 101, 241)",
  },
  {
    icon: <SiJavascript />,
    text: "JavaScript",
    color: "rgb(245, 222, 25)",
  },
  {
    icon: <SiTypescript />,
    text: "TypeScript",
    color: "rgb(45, 121, 199)",
  },
  {
    icon: <FaReact />,
    text: "React.js",
    color: "rgb(124, 197, 217)",
  },
  {
    icon: <SiRedux />,
    text: "Redux",
    color: "rgb(118, 74, 188)",
  },
  {
    icon: <SiFirebase />,
    text: "Firebase",
    color: "rgb(246, 130, 13)",
  },
  {
    icon: <SiSass />,
    text: "Sass",
    color: "rgb(204, 102, 153)",
  },
  {
    icon: <SiNextdotjs />,
    text: "Next.js",
    color: "rgb(40, 40, 40)",
  },
  {
    icon: <SiNodedotjs />,
    text: "Node.js",
    color: "rgb(41, 124, 36)",
  },
  {
    icon: <SiMongodb />,
    text: "MongoDB",
    color: "rgb(17, 97, 73)",
  },
  {
    icon: <SiExpress />,
    text: "Express.js",
    color: "rgb(140, 140, 140)",
  },
  {
    icon: <SiPython />,
    text: "Python",
    color: "rgb(53, 114, 165)",
  },
  {
    icon: <SiCplusplus />,
    text: "C++",
    color: "rgb(0, 122, 204)",
  },
  {
    icon: <SiC />,
    text: "C",
    color: "rgb(0, 102, 204)",
  },
  {
    icon: <SiCsharp />,
    text: "C#",
    color: "rgb(82, 82, 141)",
  },
];
