export const experienceConfig = [
  {
    name: "Intel Corporation Green Badge / Sii Poland",
    title: "Validation Engineer Internship",
    description: [
      "Conducting software testing in C#, including performing manual and automated tests, to ensure the high quality of Intel products.",
      "Analyzing and documenting test results, as well as collaborating with the team to optimize processes.",
      "Participating in a mentoring program focused on Python.",
    ],
    date: "Sep 2023 - Mar 2024",
  },
  {
    name: "Frontend Simplified",
    title: "Frontend Developer Internship",
    description: [
      "Transformed a completely static HTML, CSS, JavaScript and React single page application into an interactive user interface using animations, transitions and carousels",
      "Processed API requests with Axios to dynamically represent data from a cloud server and represented it through skeleton loading states, pagination and dynamic routing",
      "Utilized Git version control and the GitHub interface to work and thrive in a virtual and collaborative team environment",
    ],
    date: "Apr 2023 - Sep 2023",
  },
  {
    name: "English Me",
    title: "Private Math Tutor",
    description: [
      "Created personalized lesson plans and materials to improve students understanding and performance in math",
      "Used different teaching methods to engage students and foster a positive learning environment",
      "Implemented assessments to track progress, provide feedback, and ensure academic growth",
    ],
    date: "Jan 2023 - Current",
  },
];
